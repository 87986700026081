import React, { Component } from 'react'
import './icons.css'

class Icons extends Component{
    render() {
        return(
            <div class="icon-bar">
                <a href="https://www.facebook.com/hubnxtdoor/" target="_blank" class="facebook"><i class="fa fa-facebook"></i></a> 
                <a href="https://www.instagram.com/hubnxtdoor/?hl=en" target="_blank" class="instagram"><i class="fa fa-instagram"></i></a> 
                <a href="https://www.linkedin.com/in/hubnxt-door-716b401a1/" target="_blank" class="linkedin"><i class="fa fa-linkedin"></i></a>
            </div>
        );
    }
}

export default Icons