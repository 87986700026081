import React, { Component } from 'react'
import './contact.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import social1 from '../../Images/social1.png'
import social2 from '../../Images/social2.png'
import social3 from '../../Images/social3.png'
import Icons from '../../Components/Icons'

class Contact extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content"></div>
                <Footer />
            </div>
        );
    }
}

export default Contact