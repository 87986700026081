import React, { Component } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import './amenities.css'
import vector1 from '../../Images/vector1.png'
import vector2 from '../../Images/vector2.png'
import vector3 from '../../Images/vector3.png'
import vector4 from '../../Images/vector4.png'
import vector5 from '../../Images/vector5.png'
import vector6 from '../../Images/vector6.png'
import vector7 from '../../Images/vector7.png'
import vector8 from '../../Images/vector8.png'
import vector9 from '../../Images/vector9.png'
import Icons from '../../Components/Icons'

class Amentites extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Amenities-content" id="amenities">
                            <h2 className="Amenities-header">Amenities</h2>
                            <hr className="line14" />
                            <div className="Amenities-desc">
                                <div className="row row-section">
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector1} class="" /><span className="amenities-text">Conference Rooms</span></h2>
                                        <p>
                                            Conference rooms with TV, Pan camera and Whiteboard. Meeting rooms, Discussion rooms and Interview rooms.
                                        </p>
                                    </div>
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector2} class="" /><span className="amenities-text">High Speed Internet</span></h2>
                                        <p>
                                            300 Mbps internet, Work like Superheroes!
                                        </p>
                                    </div>
                                    <div className="amenities1 office-essentials">
                                        <h2 className="amenities-header2"><img src={vector3} class="" /><span className="amenities-text">Office Essentials</span></h2>
                                        <p>
                                            Printer, Scanner & Copier. To bring your thoughts on paper. Basic Office needs will be fulfilled by Hubnxtdoor.
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="amenities1 cafeteria-section">
                                        <h2 className="amenities-header2"><img src={vector4} class="" /><span className="amenities-text">Cafeteria</span></h2>
                                        <p>
                                            Peaceful place to have your meal. Microwave, RO water & Water heater.
                                        </p>
                                    </div>
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector5} class="" /><span className="amenities-text">Tea & Coffe</span></h2>
                                        <p>
                                            Have your leisure time and relax, with hot served Tea & Coffee.
                                        </p>
                                    </div>
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector6} class="" /><span className="amenities-text">Power Backup</span></h2>
                                        <p>
                                            We have Power Backup because show must go on without any interruption. 
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector7} class="" /><span className="amenities-text">Mini Storage with lock</span></h2>
                                        <p>
                                            Storage to keep your valuables safe. Because we care about you.. 
                                        </p>
                                    </div>
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector8} class="" /><span className="amenities-text">Air Conditioning</span></h2>
                                        <p>
                                            Feeling Hot or Cold? Keep your AC temperature as required, because it's your Dedicated AC! 
                                        </p>
                                    </div>
                                    <div className="amenities1">
                                        <h2 className="amenities-header2"><img src={vector9} class="" /><span className="amenities-text">CCTV</span></h2>
                                        <p>
                                            CCTV  to monitor in and out activities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{marginBottom:"5%"}}>
                            <h2 className="insta-header">FOLLOW US ON INSTAGRAM</h2>
                            <hr className="line12" />
                                <div class="powr-instagram-feed" id="5f007e03_1590735028"></div>
                        </div>
                        
                        <div>
                            <Icons />   
                        </div>

                    </div>
                <Footer />
            </div>
        );
    }
}

export default Amentites