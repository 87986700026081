import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { HashRouter,Switch,Route } from 'react-router-dom'
import Home from './Screens/Home'
import About from './Screens/About';
import Workspace from './Screens/Workspace';
import Pricing from './Screens/Pricing'
import Amentites from './Screens/Amenities';
import Contact from './Screens/Contact';
import Covid from './Screens/Covid'

function App() {
  return (
    <div className="App">
        <HashRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/About" component={About} />
                <Route path="/Workspace" component={Workspace} />
                <Route path="/Pricing" component={Pricing} />
                <Route path="/Amenities" component={Amentites} />
                <Route path="/Contact" component={Contact} />
                <Route path="/Covid" component={Covid} />
            </Switch>
        </HashRouter>
    </div>
  );
}

export default App;
