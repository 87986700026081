import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import hubnextlogo from '../../Images/hubnextlogo.png'
import whatsappimage from '../../Images/whatsappimage.png'
import './covid.css'
import homeicon from '../../Images/homeicon.png'
import emailicon from '../../Images/emailicon.png'
import termalscreen from '../../Images/termalscreen.png'
import  sanitizerimage from '../../Images/sanitizerimage.png'



class Covid extends Component{
    render() {
        return(
            <div>
                <div>
                <div className="top-header covid-top-header">
                    <div className="top-header-contact">
                        <a href="mailto:<nowiki>support@info@hubnxtdoor.com?" subject="HTML Link" target="_blank"  style={{color:"white", fontFamily:"Poppins",margin:"10px"}}><img src={emailicon} className="whatsapp-icon covid-whatsapp" /> info@hubnxtdoor.com</a>
                        <a href="https://wa.me/919845115729" target="_blank" style={{color:"white", fontFamily:"Poppins", margin:"10px"}}><img src={whatsappimage} className="whatsapp-icon covid-whatsapp" /> +91 9606365533</a>
                    </div>
                </div>
                    <nav  className="navbar covid-navbar">
                    <a class="navbar-brand" href="#"><img src={hubnextlogo} className="hubnext-logo"/></a>
                        <ul className="new-ul ml-auto">
                            <li>
                                <NavLink exact to="/" className="main-nav return-home" activeClassName="main-nav-active"><img src={homeicon} className="home-icon"/></NavLink>
                            </li>
                            <li>
                                <NavLink to="/Contact"><button type="button" className="btn enquire-btn">Enquire Now</button></NavLink>
                            </li>
               
                            
                        </ul>
                            
                
                </nav>
                </div>
                <div className="covid-page-content">
                    <h1>Our Response to COVID-19</h1>
                    <p>
                        Health and well-being of our members has been our top priority.  We value the importance of providing a safe and 
                        secure work place for you. Be assured that at Hubnxtdoor, all precautionary steps are taken to ensure that our centre is clean, 
                        sanitized and ready.
                    </p>
                </div>

                <div className="covid-images-section">
                    <div className="covid-images-content">
                        <div className="covid-cards">
                            <div className="card4">
                                <img src={termalscreen} class="card-img-top" />
                                <div class="card-body">
                                    <h5 class="card-title">Thermal Scanner</h5>
                                </div>
                            </div>

                            <div className="card4">
                                <img src={sanitizerimage} class="card-img-top" />
                                <div class="card-body">
                                    <h5 class="card-title">Sanitizer Dispencer</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

         

                <div className="Safety-measures">
                    <div className="Safety-content">
                        <h1>Hubnxtdoor’s COVID-19 SOP</h1>
                        <p>
                            Security and housekeeping staff will be wearing masks. Additionally, masks & Sanitizers are readily available for our members as well.
                        </p>
                        <p>
                            Our housekeeping staff have increased the frequency of the cleaning schedule. We are also sanitizing door handles and work desks.
                        </p>
                        <p>
                            Get scanned for fever through IR thermometer. Hubnxtdoor staff will scan at entry point. Please co-operate. Those having temperature 
                            above 99.7/cough/cold if detected- they shall not be allowed entry.
                        </p>
                        <p>
                            Sanitize your hand while entering and exiting.  Hand sanitizer dispensers are placed at the entry points and at all key places inside the workspace.
                        </p>
                        <p>
                            Maintain social distancing inside the office and in all common areas.
                        </p>
                        <p>
                            Wear masks at all times inside the office and in all common area. People without masks will not be allowed inside the office.
                        </p>
                        <p>
                            Use your elbow to cover face and nose while sneezing and coughing.
                        </p> 
                        <p>
                            No spitting in any of the common areas.
                        </p>
                        <p>
                            Hand shaking and hugging are disallowed.
                        </p>
                        <p>
                            Assembling of more than 5 persons in common area/pantry/meeting rooms is disallowed.
                        </p>
                        <p>
                            Please carry your own water bottles. Shared water bottles will not be provided.
                        </p>
                    </div>
                </div>

          
                <div className="covid-footer">
                <div className="covid-copyright">
                    <p>Copyright&copy; 2020 Hubnxtdoor. All Rights Reserved | Designed By <span><a href="https://www.rushbeestudios.com/">Rushbee Studios</a></span></p>
                </div>
            </div>

            </div>
        );
    }
}

export default Covid