import React, { Component } from 'react'
import './about.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Benefit from '../../Components/Benefit'
import aboutbanner from '../../Images/aboutbanner.png'
import Icons from '../../Components/Icons'

import Different1 from '../../Images/Different1.svg'
import Different2 from '../../Images/Different2.svg'
import Different3 from '../../Images/Different3.svg'
import Different4 from '../../Images/Different4.svg'
import hubnxt from '../../Video/hubnxt.mp4';

class About extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <h1 className="aboutus-header">ABOUT US</h1>
                        <hr className="line16" />
                        <div className="about-section-card1" id="aboutfirstbanner">
                           <img src={aboutbanner} className="about-banner-image" alt="" />
                        </div>
                        <div>
                            <Icons />
                        </div>
                        <div className="hub-different">
                            <div className="hub-different-content">
                                <h1 className="hub-different-header"> How is Hubnxtdoor Different</h1>
                                <hr className="line8" />
                            </div>
                            
                            <div className="different1">
                                <div className="left-side-image">
                                    <img src={Different1} className="Different1-image" />
                                </div>
                                <div className="right-side-content">
                                    <p>
                                        We make it very easy for Startups to get productive at speed by providing them a fully functional 
                                        and professional office space along with a friendly office staff, all at a pocket friendly package.
                                    </p>
                                </div>
                            </div>
                                
                            <div className="different1">
                                <div className="right-side-image">
                                    <img src={Different2} className="Different1-image" />
                                </div>
                                <div className="left-side-content">
                                    <p>
                                        We make it exciting for freelancers and work from home executives to get to work daily at a dynamic workspace 
                                        where they can focus better and also collaborate, outsource and network with others in the same space.
                                    </p>
                                </div>
                            </div>

                                
                            <div className="different1">
                            <div className="left-side-image">
                                    <img src={Different3} className="Different1-image" />
                                </div>
                                <div className="right-side-content">
                                    <p className="third-card-content">
                                        We have trained and willing work staff who are eager to respond to client's requests.
                                    </p>
                                </div>
                            </div>

                                
                            <div className="different1">
                            <div className="right-side-image">
                                    <img src={Different4} className="Different1-image" />
                                </div>
                                <div className="left-side-content">
                                    <p>
                                        We are collaborated with HR Consultants, Charted Accountants, Company Secretaries, Brand consultants, 
                                        Recruiters etc. Who are vetted by us and are available on call to help any of our clients for a small fee. 
                                        Client Satisfaction is our motto.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="benifit-section-1">
                            <Benefit />
                        </div> */}

                        <div className="Video-box" id="youtube-video">
                            <div class="embed-responsive embed-responsive-16by9 mb-4">
                                <video className='videoTag' controls controlsList="nodownload">
                                    <source src={hubnxt} type='video/mp4' />
                                </video>
                            </div>

                        </div>

                        <div className="book-tour">
                            <div className="book-tour-content">
                                <h4 className="book-tour-header">Our Modern Office Spaces<br/>
                                    Are simply Stunning
                                </h4>
                                <hr className="line7" />
                                <p>Come take a Tour and see for yourself</p>
                                <a href="https://calendly.com/hubnxtdoor/15min" target="_blank"><button type="button" className="btn book-tour-btn">BOOK A TOUR</button></a>
                            </div>
                        </div>

                    </div>
                <Footer />
            </div>
        );
    }
}

export default About