import React, { Component } from 'react'
import './benefit.css'
import vector1 from '../../Images/vector1.png'
import vector2 from '../../Images/vector2.png'
import vector3 from '../../Images/vector3.png'
import vector4 from '../../Images/vector4.png'
import vector5 from '../../Images/vector5.png'
import vector6 from '../../Images/vector6.png'
import vector7 from '../../Images/vector7.png'
import vector8 from '../../Images/vector8.png'
import vector9 from '../../Images/vector9.png'

class Membership extends Component{
    render() {
        return(
            <div className="benefit-types">
            <h6 className="benefit-types-header">our benefits</h6>
            <h3 className="benefit-types-text">Your Comfort is our Priority</h3>
            <hr className="line2" />

            <p className="benefit-types-text2">
                Furnished Office Space. 100% PowerBack, High-Speed Internet & Added Benefits.
            </p>
            <div className="benefit-content">
                <div className="">

                <div className="row row3">
                    <div className="col-md-4 modify-header1 amenities">
                        <h2 className="benefit-header"><img src={vector1} class="img-responsive" /><span className="benefit-header2">Conference Rooms</span></h2>
                    </div>
                    <div className="col-md-4 amenities11">
                        <h2 className="benefit-header"><img src={vector2} class="img-responsive vector2" /><span className="benefit-header2">High Speed Internet</span></h2>
                    </div>
                    <div className="col-md-4 amenities11">
                        <h2 className="benefit-header"><img src={vector3} class="img-responsive vector3" /><span className="benefit-header2">Office Essentials</span></h2>
                    </div>
                </div>

            <div className="row row4">
                <div className="col-md-4 modify-header4 amenities11">
                    <h2 className="benefit-header modify-header1 modify-header11"><img src={vector4} class="img-responsive" /><span className="benefit-header2 benefit-header22">Cafeteria</span></h2>
                </div>
                <div className="col-md-4 amenities11">
                    <h2 className="benefit-header modify-header2"><img src={vector5} class="img-responsive" /><span className="benefit-header2">Tea & Coffee</span></h2>
                </div>
                <div className="col-md-4 amenities11">
                    <h2 className="benefit-header modify-header8"><img src={vector6} class="img-responsive" /><span className="benefit-header2">Power Backup</span></h2>
                </div>
            </div>

            <div className="row row5">
                <div className="col-md-4 modify-header5 amenities11">
                    <h2 className="benefit-header  modify-header6"><img src={vector7} class="img-responsive" /><span className="benefit-header2 benefit-header23">Mini Storage with lock</span></h2>
                </div>
                <div className="col-md-4 amenities11">
                    <h2 className="benefit-header modify-header3"><img src={vector8} class="img-responsive" /><span className="benefit-header2">Air Conditioning</span></h2>
                </div>
                <div className="col-md-4 amenities11">
                    <h2 className="benefit-header modify-header9"><img src={vector9} class="img-responsive" /><span className="benefit-header2">CCTV</span></h2>
                </div>
            </div>
                </div>
        
            </div>
            
       </div>
        );
    }
}

export default Membership
