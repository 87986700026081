import React, { Component } from 'react'
import './membership.css'
import { Link } from 'react-router-dom'

class Membership extends Component{
    render() {
        return(
            <div className="Membership">
                <div className="membership-content">
                    <h6 className="membership-header">
                        MEMBERSHIP TYPES
                    </h6>
                    <hr className="line3" />
                    
                    <div className="pricing-cards">
                        <div className="card card2 wow flipInX home-card">
                            <div className="card-content">
                                <h3 className="card-title">Virtual</h3>
                                <p>ADDRESS & SHIPPING</p>
                                
                               
                                
                              
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 1499</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4" />
                                <div className="member-card-text">
                                    <p>
                                        Send and receive packages and mail from our midtown location. 
                                        Conference room bookings available.
                                    </p>
                                </div>
                            <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>

                        <div className="card card2 wow flipInX home-card">
                       
                            <div className="card-content">

                                <h3 className="card-title">Desk</h3>
                                <p>HOT DESK</p>
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 2499</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4" />
                                <div className="member-card-text">
                                    <p>
                                        Monday through Saturday access to open workspace with 
                                        conference room bookings available.
                                    </p>
                                </div>
                                <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>

                        <div className="card card2 wow flipInX home-card">
                            <div className="card-content">
                                <h3 className="card-title">Dedicated</h3>
                                <p>PERMANENT DESK</p>
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 2999</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4" />
                                <div className="member-card-text">
                                    <p>
                                        Get a dedicated working space for a permanent use. 
                                        Meeting and conference rooms are available.
                                    </p>
                                </div>
                                <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>

                        <div className="card card2 wow flipInX home-card">
                            <div className="card-content">
                                <h3 className="card-title">Office</h3>
                                <p>PRIVATE CABINS</p>
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 6999</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4" />
                                <div className="member-card-text">
                                    <p>
                                        Well designed affordable and budget friendly offices for all types of corporate customers & startups.
                                    </p>
                                </div>
                                <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>
                    </div>

                </div>
              
            </div>
        );
    }
}

export default Membership
