import React, { Component } from 'react'
import './home.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import cardimage1 from '../../Images/cardimage1.png'
import teamimage1 from '../../Images/teamimage1.png'

import Benefit from '../../Components/Benefit'
import Membership from '../../Components/Membership'

import { HashLink as Link } from 'react-router-hash-link'
import $ from 'jquery'
import Icons from '../../Components/Icons'
import homepagebanner from '../../Images/homepagebanner.jpeg'
class Home extends Component{

    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="banner1">
                            <img src={homepagebanner} className="banner-image"/>
                            {/* <div className="home-banner-content  wow bounceInDown">
                                <h1 className="home-banner-header">Co-Working Spaces<br/>for  Success</h1>
                                <p className="home-banner-text">
                                    There are communities of people all over the city who are<br/> gathering to share the space
                                    and to help each other. We are here to help you.
                                </p>
                            </div> */}
                        </div>
                        <Link to="/About#youtube-video"><button type="button" className="btn video-tour-btn">Take our Video Tour</button></Link>
                        <div>
                            <Icons />
                        </div>
                        <div className="card1 wow flipInY" >
                           <div className="row">
                               <div className="col-md-6">
                                    <img src={cardimage1} className="cardimage1"/>
                               </div>
                               <div className="col-md-6 content-side">
                                    <p className="cardheader">ABOUT US</p>
                                    <h4 className="card-text">
                                        We are Built for<br/>
                                        Business - Explore<br/>
                                        Us Today !!
                                    </h4>
                                    <hr className="line" /><br/>

                                    <div className="row row2">
                                        <div className="col-md-7">
                                            <p className="card-text2">
                                                Our Co-working Spaces
                                                provides amazing office space
                                                to all out of the box thinkers
                                            </p>
                                        </div>
                                        <div className="col-md-5">
                                        <Link to="/About#aboutfirstbanner"><button type="button" className="btn btn-learn-more">KNOW MORE</button></Link>
                                        </div>
                                    </div>

                               </div>
                           </div>
                        </div>

                        <div id="benefit">
                            <Benefit />
                        </div>
                    

                        <div>
                            <Membership />
                        </div>
                        <div className="custom-type">
                            <h2 className="custom-type-header">Hourly</h2>
                            <p className="custom-type-text">Commitment-free workspace when you need them.</p>
                            <p>
                                Conference Rooms/<br/>
                                Meeting Rooms / Private<br/>
                                Cabins / Desks by the hour
                            </p>
                            <hr className="line13" />
                            <a href="https://wa.me/919606365533" target="_blank"><button type="button" className="btn whatsapp-btn"><i class="fa fa-whatsapp" aria-hidden="true"></i> +91 96063 65533</button></a>
                        </div>

                        {/* <div className="Team-card">
                            <div className="row">
                                <div className="col-md-6 content-side">
                                        <p className="cardheader">TEAM</p>
                                        <h4 className="card-text">
                                            Connect with<br/>
                                            Friends, Find your<br/>
                                            Soulmates
                                        </h4>
                                        <hr className="line" /><br/>

                                        <div className="row row2">
                                            <div className="col-md-7">
                                                <p className="card-text2">
                                                    Our Co-working Spaces
                                                    provides amazing office space
                                                    to all out of the box thinkers
                                                </p>
                                            </div>
                                            <div className="col-md-5">
                                            <button type="button" className="btn btn-learn-more">KNOW MORE</button>
                                            </div>
                                        </div>

                                </div>

                                <div className="col-md-6">
                                        <img src={teamimage1} className="cardimage1 cardimage2"/>
                                </div>
                            </div>
                        </div> */}
                  
                      

                        {/* <div className="Instagram">
                            <div class="elfsight-app-8f9e4cbb-989f-4126-84eb-5ce8ba2e0b33"></div>
                        
                              
                        </div> */}
               

                   <div className="Testimonials">
                            <div className="Testimonials-content">
                                <div className="testimonial-header-content">
                                    <h3 className="testimonial-header">Testimonials</h3>
                                    <hr className="line5" />
                                    <h3 style={{color: "orange"}}><q id="b"></q></h3>
                                </div>
                            </div>
                            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <p>
                                            Fantastic place to work and comparatively less cost. I really like the atmosphere here, 
                                            gives positive energy to focus and work. Staff and the owner is really friendly and supportive. 
                                            They actively take care of all your needs. It has a wonderful high speed uninterrupted internet. 
                                            In short this place is the best and my experience is awesome.
                                        </p>
                                        <p>- Mahesh joshi</p>
                                    </div>
                                    <div class="carousel-item">
                                        <p>
                                            Such a lovely place to work out of! Priya is a very warm host and her team ensures all your needs are taken care of. 
                                            Fantastic internet connectivity and enough privacy is ensured for each client. This is a great solution for a working 
                                            space away from home and office and the charges are extremely reasonable.
                                        </p>
                                        <p>- Rekha Padmanabhan</p>
                                    </div>
                                    <div class="carousel-item">
                                        <p>
                                            Nicely done office with a lot of facility at a affordable rate. Friendly owner who listens to all your needs.
                                        </p>
                                        <p>- Shekhar Yadav</p>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true" style={{color:"black"}}></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                 

                    </div>
                <Footer />
            </div>
        );
    }
}

export default Home
