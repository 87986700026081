import React, { Component } from 'react'
import './footer.css'
import social1 from '../../Images/social1.png'
import social2 from '../../Images/social2.png'
import social3 from '../../Images/social3.png'

class Footer extends Component{
    render() {
        return(
            <div className="footer-section">
                <footer>
                <div clasName="Top-footer">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="address-content">
                                <p className="form-header1">CONTACT US</p>
                                <h1 className="form-header">Say Hello</h1>
                                <hr className="line6 line11" />
                               <a class="mobilesOnly" href="tel:+919606365533"><p style={{textAlign:"start",color:"black"}}><i class="fa fa-phone" aria-hidden="true"></i> +91 9606365533</p></a>
                                <p style={{textAlign:"start"}}>
                                    No.13-14, 2nd floor, Kothnur Main<br/>
                                    Rd, opposite Corporation Bank,<br/>
                                    JP Nagar 7th Phase, Bengaluru,<br/>
                                    Karnataka 560078
                                </p>
                                {/* <div className="social-links">
                                    <a href="https://www.facebook.com/hubnxtdoor/" target="_blank"><img src={social1} alt="" className="fb-link" /></a>
                                    <a href="https://www.instagram.com/hubnxtdoor/?hl=en" target="_blank"><img src={social2} alt="" className="insta-link" /></a>
                                    <a href="https://www.linkedin.com/in/hubnxt-door-716b401a1/" target="_blank"><img src={social3} alt="" className="insta-link" /></a>
                                </div> */}
                                <h3 style={{textAlign:"start"}} className="form-header">Business Hours</h3>
                                <hr className="line10" />
                                <p style={{textAlign:"start"}}>Monday To Saturday: <span className="timings1">9:00 AM - 8:00 PM</span></p>
                                <p style={{textAlign:"start"}}>Sunday: <span className="timings3">Closed</span></p>
                                <a href="https://calendly.com/hubnxtdoor/15min" target="_blank"><button type="button" className="btn book-tour-btn btn123">BOOK A TOUR</button></a>
                            </div>
                            <hr className="line15" />
                        </div>
                  
                        <div className="col-md-6">
                            <form name="contact" method="post"  data-netlify="true" className="contact-form">
                                <input type="hidden" name="form-name" value="contact" />
                                <p className="form-header1">HAVE A QUESTION ?</p>
                                <h1 className="form-header">Send Message</h1>
                                <hr className="line6" />
                                    <p>
                                        <input type="text" name="name" placeholder="Full Name" required/> 
                                    </p>
                                    <p>
                                        <input type="number" name="number" placeholder="Mobile Number" required/>
                                    </p>
                                    <p>
                                        <input type="email" name="email" placeholder="Email Address" required/>
                                    </p>
                                    <p>
                                        <select name="role[]" className="drop-down">
                                            <option value="--Select--">--Select Package--</option>
                                            <option value="Virtual Office">Virtual Office</option>
                                            <option value="Hot Desk">Hot Desk</option>
                                            <option value="Dedicated Desk">Dedicated Desk</option>
                                            <option value="Private Cabbin">Private Cabin</option>
                                        </select>
                                    </p>
                                    <p>
                                        <textarea name="message" placeholder="Enter your message" className="message-area"></textarea>
                                    </p>
                                    <p>
                                    <div data-netlify-recaptcha="true"></div>
                                    </p>
                                
                                    <p>
                                        <button type="submit" className="btn submit-btn">SUBMIT</button>
                                    </p>
                            </form>
                        </div>
                    </div>
                </div>
              
            </footer>
            <div className="Bottom-footer">
                <div id="map-container-google-1" class="z-depth-1 map-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.390540969266!2d77.58104375093582!3d12.882590520264925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae153d3b268c9d%3A0x326502f92363f793!2sHubnxtdoor!5e0!3m2!1sen!2sin!4v1589803585142!5m2!1sen!2sin"  frameborder="0"
                    style={{border:"0"}}  allowfullscreen className="map-image"></iframe>
                </div>
                <div className="bottom-copyright">
                    <p>Copyright&copy; 2020 Hubnxtdoor. All Rights Reserved | Designed By <span><a href="https://www.rushbeestudios.com/">Rushbee Studios</a></span></p>
                </div>
            </div>
         
          
           
            </div>
     
        );
    }
}

export default Footer

