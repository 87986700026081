import React, { Component } from 'react'
import './workspace.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import $ from 'jquery'
import Icons from '../../Components/Icons'

import lobby1 from '../../Images/lobby1.jpeg'
import privatecabin1 from '../../Images/privatecabin1.jpeg'
import privatecabin2 from '../../Images/privatecabin2.jpeg'
import privatecabin3 from '../../Images/privatecabin3.jpeg'
import cafeteria1 from '../../Images/cafeteria1.jpeg'
import cafeteria2 from '../../Images/cafeteria2.jpeg'
import conference1 from '../../Images/conference1.jpeg'
import conference2 from '../../Images/conference2.jpeg'
import conference3 from '../../Images/conference3.jpeg'
import hotdesk1 from '../../Images/hotdesk1.jpeg'
import hotdesk2 from '../../Images/hotdesk2.jpeg'
import outside1 from '../../Images/outside1.jpeg'
import outside2 from '../../Images/outside2.jpeg'

import webcam from '../../Images/webcam.jpeg'

class Workspace extends Component{
    componentDidMount = () =>{
        $(document).ready(function(){
            $(".button").click(function(){
                var name = $(this).attr("data-filter");
                if(name === "all"){
                    $(".filter").show("2000");
                }
                else{
                    $(".filter").not("."+name).hide("2000");
                    $(".filter").filter("."+name).show("2000");
                }
            });
            
            $(".navigation a").click(function(){
                $(this).addClass("active").siblings().removeClass("active");
            })
        });

        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     


 
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="gallery-page">
                            <div className="gallery-content">
                                <div>
                                    <Icons />   
                                </div>

                                <div className="latest-work">
                                    <h1 className="work-text1">Workspace <span className="work-text2">Gallery</span></h1>
                                    <div className="navigation">
                                        <a  data-filter="lobby" class="button btn btn-gallery ">Lobby</a>
                                        <a  data-filter="privatecabin" class="button btn btn-gallery">Private Cabin</a>
                                        <a  data-filter="cafeteria" class="button btn btn-gallery">Cafeteria</a>
                                        <a  data-filter="conference" class="button btn btn-gallery">Conference Room</a>
                                        <a  data-filter="hotdesk" class="button btn btn-gallery">Hotdesk</a>
                                        <a  data-filter="outside" class="button btn btn-gallery">Outside View</a>
                                    </div>

                                    <div id="fashion-portfolio" data-lightbox="mygallery">
                                        {/* first column */}
                                        <div className="row row34"> 
                                            <div className="column">
                                                <div className="filter lobby" >
                                                    <img src={lobby1} id="img"/>
                                                </div>
                                          
                                            
                                            
                                            
                                            </div>     
                                            {/* Second column */}
                                            <div className="column">     
                                            
                                                {/* <div className="filter privatecabin" >
                                                    <img src={privatecabin1} id="img"/>
                                                </div>   */}
                                            
                                                <div className="filter conference" >
                                                    <img src={conference1} id="img" />
                                                </div>
                                                <div className="filter lobby" >
                                                    <img src={webcam} id="img" />
                                                </div>
                                            
                                            
                                            </div>
                                            {/* Third column */}
                                            <div className="column">
                                        
                                                <div className="filter privatecabin">
                                                    <img src={privatecabin2} id="img" />
                                                </div>
                                            
                                                <div className="filter conference" >
                                                    <img src={conference2} id="img" />
                                                </div>
                                            
                                                
                                    
                                                <div className="filter hotdesk">
                                                    <img src={hotdesk1} id="img"/>
                                                </div>
                                            </div>

                                            {/* Fourth column */}
                                            <div className="column">
                                                <div className="filter privatecabin" >
                                                    <img src={privatecabin3} id="img" />
                                                </div>
                                               
                                                <div className="filter cafeteria" >
                                                    <img src={cafeteria1} id="img"/>
                                                </div>
                                                <div className="filter hotdesk" >
                                                    <img src={hotdesk2} id="img" />
                                                </div>
                                                <div className="filter outside" >
                                                    <img src={outside1} id="img" />
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
            
                                </div>

                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Workspace