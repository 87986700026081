import React, { Component } from 'react'
import './header.css'
import $ from 'jquery'
import { HashLink as Link } from 'react-router-hash-link'
import { NavLink } from 'react-router-dom'
import hubnextlogo from '../../Images/hubnextlogo.png'
import menubar from '../../Images/menubar.png'
import whatsappimage from '../../Images/whatsappimage.png'
import emailicon from '../../Images/emailicon.png'
class Header extends Component{
 
    render() {
        return(
            <div className="Header">
                <div className="top-header">
                    <div className="top-header-contact">
                        <Link to="/About#youtube-video"><button type="button" className="btn top-vodeo-btn">Take our Video Tour</button></Link>
                        <NavLink to="/Covid"><button type="button" className="btn covid-btn"><span className="our-response">Our Response to</span> COVID-19</button></NavLink>
                        <a href="mailto:<nowiki>support@info@hubnxtdoor.com?" subject="HTML Link" target="_blank"  style={{color:"white", fontFamily:"Poppins",margin:"10px"}}><img src={emailicon} className="whatsapp-icon" /> info@hubnxtdoor.com</a>
                        <a href="https://wa.me/919845115729" target="_blank" style={{color:"white", fontFamily:"Poppins", margin:"10px"}}><img src={whatsappimage} className="whatsapp-icon" /> +91 9606365533</a>
                    </div>
               
                </div>
                <nav  class="navbar">
                    <a class="navbar-brand" href="#"><img src={hubnextlogo} className="hubnext-logo"/></a>
                    <NavLink to="/Covid" className="navbar-brand2"><button type="button" className="btn covid-btn">COVID-19 SOP</button></NavLink>
                    <a class="navbar-brand1" href="https://wa.me/919606365533" target="_blank"><img src={whatsappimage} className="whatsapp-image"/></a>
                    <input type="checkbox" id="check" />
                    <label for="check" className="checkbtn">
                        <img src={menubar} className="checkbtn-image" />
                    </label>
                   
                    <ul>
                        <li>
                            <NavLink exact to="/" className="main-nav" activeClassName="main-nav-active"><i class="fa fa-home" aria-hidden="true"></i> Home</NavLink>
                        </li>

                        <li>
                            <i class="fa fa-users" aria-hidden="true"></i>
                            <NavLink to="/About" className="main-nav" activeClassName="main-nav-active">About us</NavLink>
                        </li>

                        <li>
                            <i class="fa fa-database" aria-hidden="true"></i>
                            <NavLink to="/Pricing" className="main-nav" activeClassName="main-nav-active">Pricing</NavLink>
                        </li>

                        <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <NavLink to="/Amenities" className="main-nav" activeClassName="main-nav-active">Amenities</NavLink>
                        </li>

                        <li>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <NavLink to="/Workspace" className="main-nav" activeClassName="main-nav-active">Workspace</NavLink>
                        </li>

                        <li>
                            <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
                            <NavLink to="/Contact" className="main-nav" activeClassName="main-nav-active">Contact us</NavLink>
                        </li>

                        <label for="check" className="hide-menu-btn checkbtn">
                            <i className="fa fa-times"></i>
                        </label>
                    </ul>
                </nav>
            </div>
        );
    }
}
export default Header