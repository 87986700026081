import React, { Component } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Membership from '../../Components/Membership'
import Benefit from '../../Components/Benefit'
import Icons from '../../Components/Icons'
import './pricing.css'
import { Link } from 'react-router-dom'
import hotdesk3 from '../../Images/hotdesk3.jpeg'
import privatecabin4 from '../../Images/privatecabin4.jpeg'
import dedicated from '../../Images/dedicated.jpeg'
import virtual from '../../Images/virtual.jpeg'

class Pricing extends Component{
    render() {
        return(
            <div id="site-wraper">
                <Header />
                    <div id="page-content">
                        <div style={{marginBottom:"5%"}}>
                        <div className="Membership">
                <div className="membership-content">
                    <h6 className="membership-header">
                        MEMBERSHIP TYPES
                    </h6>
                    <hr className="line3" />
                    
                    <div className="pricing-cards">
                        <div className="card card2 wow flipInX">
                            <div className="card-content">
                                <img src={virtual} class="card-img-top pricingimg" />
                                <h3 className="card-title">Virtual</h3>
                                <p>ADDRESS & SHIPPING</p>
                
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 1499</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4 pricingl4" />
                                <div className="member-card-text">
                                    <p>
                                        Send and receive packages and mail from our midtown location. 
                                        Conference room bookings available.
                                    </p>
                                </div>
                            <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>

                        <div className="card card2 wow flipInX">
                       
                            <div className="card-content">
                                <img src={hotdesk3} class="card-img-top pricingimg" />
                                <h3 className="card-title">Desk</h3>
                                <p>HOT DESK</p>
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 2499</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4 pricingl4" />
                                <div className="member-card-text">
                                    <p>
                                        Monday through Saturday access to open workspace with 
                                        conference room bookings available.
                                    </p>
                                </div>
                                <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>

                        <div className="card card2 wow flipInX">
                            <div className="card-content">
                                <img src={dedicated} class="card-img-top pricingimg" />
                                <h3 className="card-title">Dedicated</h3>
                                <p>PERMANENT DESK</p>
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 2999</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4 pricingl4" />
                                <div className="member-card-text">
                                    <p>
                                        Get a dedicated working space for a permanent use. 
                                        Meeting and conference rooms are available.
                                    </p>
                                </div>
                                <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>

                        <div className="card card2 wow flipInX">
                            <div className="card-content">
                                <img src={privatecabin4} class="card-img-top pricingimg" />
                                <h3 className="card-title">Office</h3>
                                <p>PRIVATE CABINS</p>
                                <div className="pricing">
                                    <p className="starting">Starting @</p>
                                    <h3 className="price"><span className="currency-symbol">&#x20B9;</span> 6999</h3>
                                    <p>PER MONTH</p>
                                </div>
                                <hr className="line4 pricingl4" />
                                <div className="member-card-text">
                                    <p>
                                        Well designed affordable and budget friendly offices for all types of corporate customers & startups.
                                    </p>
                                </div>
                                <Link to="/Contact"><button type="button" className="btn join-now-btn">JOIN NOW</button></Link>
                            </div>
                        </div>
                    </div>

                </div>
              
            </div>
                        </div>
                        <div className="custom-type">
                            <h2 className="custom-type-header">Hourly</h2>
                            <p className="custom-type-text">Commitment-free workspace when you need them.</p>
                            <p>
                                Conference Rooms/<br/>
                                Meeting Rooms / Private<br/>
                                Cabins / Desks by the hour
                            </p>
                            <hr className="line13" />
                            <a href="https://wa.me/919606365533" target="_blank"><button type="button" className="btn whatsapp-btn"><i class="fa fa-whatsapp" aria-hidden="true"></i> +91 9606365533</button></a>
                        </div>
                        <div>
                            <Icons />   
                        </div>
                        <div style={{marginBottom:"5%"}}>
                            <Benefit />
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Pricing
